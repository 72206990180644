/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components), {Text} = _components;
  if (!Text) _missingMdxReference("Text", true);
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    children: `Hi, I'm Tom`,
    sx: {
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }
  }), "\n", React.createElement(_components.p, null, "A Software Engineer & Architect."), "\n", React.createElement(_components.p, null, "I'm an Australian who enjoys strumming the guitar,\nMuay Thai, and has a weakness for bacon & egg rolls on\na Saturday morning."), "\n", React.createElement(_components.p, null, "I also love building things and to scratch that itch,\nI tinker with ", React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/dotnet/"
  }, ".NET"), ", ", React.createElement(_components.a, {
    href: "https://learn.microsoft.com/en-us/azure/architecture/"
  }, "Microsoft Azure"), ", ", React.createElement(_components.a, {
    href: "https://www.python.org"
  }, "Python"), " and ", React.createElement(_components.a, {
    href: "https://reactjs.org"
  }, "React"), " with a focus\non System Design. I do consume a lot of startup oriented content from ", React.createElement(_components.a, {
    href: "https://news.ycombinator.com"
  }, "Hacker News"), " but the stuff here is ", React.createElement(_components.a, {
    href: "https://justforfunnoreally.dev"
  }, "just for fun"), "."), "\n", React.createElement(_components.p, null, "Currently working as a Senior Software Engineer at ", React.createElement(_components.a, {
    href: "https://www.azenix.com.au"
  }, "Azenix")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
