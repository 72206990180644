/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {Title} = _components;
  if (!Title) _missingMdxReference("Title", true);
  return React.createElement(React.Fragment, null, React.createElement(Title, {
    text: "Projects"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gemislandserverless.netlify.app"
  }, "Gem Island - a match three game ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://soundsort.net"
  }, "Sound Sort - a web app to analyse and curate your Spotify playlists")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
